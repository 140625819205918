import { Widget } from '@typeform/embed-react';
import { useMemo } from 'react';

import {
  EnumsOnboardingTaskName,
  EnumsOnboardingTaskOperation,
  EnumsOrgSubscriptionStatus,
} from '@lp-lib/api-service-client/public';

import { InfoIcon } from '../../components/icons/InfoIcon';
import {
  useCanScheduleLiveEvents,
  useMyOrganization,
  useMyOrganizationFeatureChecker,
} from '../../components/Organization/hooks/organization';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { useUser } from '../../components/UserContext';
import config from '../../config';
import { apiService } from '../../services/api-service';
import { OrganizerUtils } from '../../types';

export function BookLiveGameWidget(props: {
  onSubmit?: () => void;
}): JSX.Element {
  const user = useUser();
  const handleFormSubmission = async () => {
    if (user.organizer?.organization?.id) {
      await apiService.onboarding.operateTask(
        EnumsOnboardingTaskName.OnboardingTaskNameBookLiveShow,
        {
          orgId: user.organizer.organization.id,
          operation:
            EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkDone,
        }
      );
    }
    props.onSubmit?.();
  };

  return (
    <Widget
      id={config.misc.typeFormIdLiveBooking}
      className='absolute inset-0'
      hidden={{
        companyName: user.organizer?.organization?.name ?? '',
        name: OrganizerUtils.GetDisplayName(user.organizer),
        firstName: user.organizer?.firstName ?? '',
        email: user.email ?? '',
        plan: user.organizer?.organization?.subscription?.plan ?? '',
      }}
      onSubmit={handleFormSubmission}
    />
  );
}

function ReachOutToAdmin(): JSX.Element {
  return (
    <div className='w-full h-full flex items-center justify-center text-white text-xl font-medium text-center'>
      Please reach out to your organization admin to schedule a live event.
    </div>
  );
}

function LunaParkPreview() {
  return (
    <iframe
      style={{ aspectRatio: '16/9' }}
      className='w-full'
      src='https://www.youtube.com/embed/40dEa_UX07g?si=qnNaPNKQXeABq4bG'
      title='YouTube video player'
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      allowFullScreen
    />
  );
}

function NotInPlan() {
  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-6 text-white'>
      <div className='text-tertiary font-bold text-3.5xl'>
        Book your live event today!
      </div>
      <div className='w-full flex justify-center'>
        <div className='w-1/2 min-w-125'>
          <LunaParkPreview />
          <div className='pt-6 text-lg text-center'>
            Although live events are not included in your plan, you can still
            email{' '}
            <a href='mailto:bookings@lunapark.com' className='text-primary'>
              bookings@lunapark.com
            </a>{' '}
            to find out more and schedule an event for an additional fee.
          </div>
        </div>
      </div>
    </div>
  );
}

function Trialing() {
  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-6 text-white'>
      <div className='text-tertiary font-bold text-3.5xl'>
        Book your live event today!
      </div>
      <div className='w-full flex justify-center'>
        <div className='w-1/2 min-w-125'>
          <LunaParkPreview />
          <div className='pt-6 text-lg text-center'>
            Contact{' '}
            <a href='mailto:bookings@lunapark.com' className='text-primary'>
              bookings@lunapark.com
            </a>{' '}
            now to schedule your event post-trial.
          </div>
        </div>
      </div>
    </div>
  );
}

function Discount(props: { discount: number }) {
  return (
    <div className='w-full h-full flex flex-col items-center justify-center gap-6 text-white'>
      <div className='text-tertiary font-bold text-3.5xl'>
        Book your live event today and save {props.discount}%!
      </div>
      <div className='w-full flex justify-center'>
        <div className='w-1/2 min-w-125'>
          <LunaParkPreview />
          <div className='pt-6 text-lg text-center'>
            Email{' '}
            <a href='mailto:bookings@lunapark.com' className='text-primary'>
              bookings@lunapark.com
            </a>{' '}
            to find out more and schedule your preferred day/time.
          </div>
        </div>
      </div>
    </div>
  );
}

function Unlimited() {
  return (
    <>
      <header
        className={`
          w-full lg:w-1/4
          py-12.5 lg:pt-24
          px-12.5
          space-y-5
          bg-lp-gray-009
          border-secondary
          border-b lg:border-b-0 lg:border-r
        `}
      >
        <div className='text-white font-bold text-3.5xl leading-snug'>
          Schedule a live hosted event!
        </div>
        <div className='text-white font-medium text-lg'>
          Take your next team building event to another level. Book a live
          hosted experience your team will love!
        </div>
        <div className='mt-2 text-green-001 italic text-sm flex gap-2'>
          <InfoIcon
            className='flex-none mt-0.5 w-4 h-4 fill-current text-green-001'
            color='fill-current'
          />
          Luna Park Live Events are included in your subscription at no extra
          charge!
        </div>
      </header>
      <div className='relative flex-1 w-full h-full'>
        <BookLiveGameWidget />
      </div>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default function Live(): JSX.Element {
  const organization = useMyOrganization();
  const canScheduleLiveEvents = useCanScheduleLiveEvents();
  const featureChecker = useMyOrganizationFeatureChecker();
  const liveBookingDiscount = featureChecker.getLiveBookingDiscount();
  const unlimitedAccess = featureChecker.hasUnlimitedLiveBooking();

  const body = useMemo(() => {
    const shouldCheckTrialStatus =
      !organization?.settings?.allowLiveEventsDuringTrial;

    if (!canScheduleLiveEvents) {
      return <ReachOutToAdmin />;
    } else if (
      shouldCheckTrialStatus &&
      organization?.subscription?.status ===
        EnumsOrgSubscriptionStatus.OrgSubscriptionStatusTrialing
    ) {
      return <Trialing />;
    } else if (unlimitedAccess) {
      return <Unlimited />;
    } else if (liveBookingDiscount) {
      return <Discount discount={liveBookingDiscount} />;
    } else {
      return <NotInPlan />;
    }
  }, [
    canScheduleLiveEvents,
    liveBookingDiscount,
    organization?.settings?.allowLiveEventsDuringTrial,
    organization?.subscription?.status,
    unlimitedAccess,
  ]);

  return (
    <PublicHomeLayout>
      <div className='flex-1 overflow-y-auto scrollbar bg-game-library bg-w-full bg-no-repeat bg-top bg-local flex flex-col lg:flex-row'>
        {body}
      </div>
    </PublicHomeLayout>
  );
}
